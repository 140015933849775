<template>
  <div class="detail" id="detail" ref="detail">
    <div id="pdfDom">
      <swiper class="swiper1" :options="swiperOptions1" v-if="info.houseimgs">
        <!-- 视频 -->
        <swiper-slide v-if="info.js_video">
          <video :src="info.js_video" controls autoplay></video>
        </swiper-slide>
        <!-- 寄宿家庭成员 -->
        <template v-if="info.houseimgs.jiaren">
          <swiper-slide v-for="item in info.houseimgs.jiaren" :key="item">
            <!-- <img :src="item" alt="" /> -->
            <div class="bg" :style="`background:url(${item}) center center / contain no-repeat;`"></div>
          </swiper-slide>
        </template>

        <!-- 房屋外观 -->
        <template v-if="info.houseimgs.waiguan">
          <swiper-slide v-for="item in info.houseimgs.waiguan" :key="item">
            <!-- <img :src="item" alt="" /> -->
            <div class="bg" :style="`background:url(${item}) center center / contain no-repeat;`"></div>
          </swiper-slide>
        </template>
        <!-- 起居室 -->
        <template v-if="info.houseimgs.qiju">
          <swiper-slide v-for="item in info.houseimgs.qiju" :key="item">
            <!-- <img :src="item" alt="" /> -->
            <div class="bg" :style="`background:url(${item}) center center / contain no-repeat;`"></div>
          </swiper-slide>
        </template>
        <!-- 厨房 -->
        <template v-if="info.houseimgs.chufang">
          <swiper-slide v-for="item in info.houseimgs.chufang" :key="item">
            <!-- <img :src="item" alt="" /> -->
            <div class="bg" :style="`background:url(${item}) center center / contain no-repeat;`"></div>
          </swiper-slide>
        </template>
        <!-- 卧室 -->
        <template v-if="info.houseimgs.woshi">
          <swiper-slide v-for="item in info.houseimgs.woshi" :key="item">
            <!-- <img :src="item" alt="" /> -->
            <div class="bg" :style="`background:url(${item}) center center / contain no-repeat;`"></div>
          </swiper-slide>
        </template>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
      <swiper class="swiper1" :options="swiperOptions1" v-else>
        <swiper-slide>
          <img :src="require('@/assets/img/moren.png')" alt="" />
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
      </swiper>
      <div class="cnt">
        <div class="t1 box">
          <div class="title">
            {{ info.chengshi ? info.chengshi.name + " -" : "" }}
            {{ info.full_name | empty }}
          </div>
          <!-- <p><span>地理位置：</span>{{info.js_site}}</p> -->
          <p v-if="schoolname != ''"><span>学校名称：</span>{{ schoolname }}</p>
          <!-- <p v-if="distance != ''">
            <span>路程/时长：</span>{{ distance }}km / 约{{
              (distance * 5).toFixed(0)
            }}分钟
          </p> -->
          <p v-if="info.js_jiesong">
            <span>提供接送：</span>{{ info.js_jiesong | empty }}
          </p>
        </div>


        <div class="t2">
          <div class="title">房东信息</div>
          <div class="box">
            <p v-if="info.js_birthday">
              <span>出生日期：</span>{{ info.js_birthday.substr(0, 10) }}
            </p>
            <p v-if="info.js_jlzt"><span>在美状态：</span>{{ info.js_jlzt }}</p>
            <p v-if="info.js_race"><span>族裔：</span>{{ info.js_race }}</p>
            <p v-if="info.js_sex">
              <span>房东性别：</span>{{ info.js_sex || "不详"}}
            </p>
            <p v-if="info.js_marriage">
              <span>婚姻状况：</span>{{ info.js_marriage }}
            </p>
            <p v-if="info.js_lifespan">
              <span>居住年限：</span>{{ info.js_lifespan }}
            </p>
            <p v-if="info.chengshi != null && info.chengshi.csyw != null">
              <span>所在城市：</span>{{ info.chengshi.csyw }}
            </p>
            <p v-if="info.js_education">
              <span>学历：</span>{{ info.js_education }}
            </p>
            <p v-if="info.js_beliefs">
              <span>宗教信仰：</span>{{ info.js_beliefs }}
            </p>
            <p v-if="info.js_occupation">
              <span>目前职业：</span>{{ info.js_occupation }}
            </p>
            <p v-if="info.js_english_level">
              <span>英文水平：</span>{{ info.js_english_level }}
            </p>
          </div>
        </div>
        <div class="t5" v-if="info.family && info.family.length > 0">
          <div class="title">住家成员信息</div>
          <div class="box">
            <div class="t5_tab">
              <div v-for="(item, key) in info.family" :key="key" :class="{ active: tabindex == key }"
                @click="tabclick(key)">
                {{ item.js_applicant }}
              </div>
            </div>
            <p v-if="info.family[tabindex].name">
              <span>姓名：</span>{{ info.family[tabindex].name | empty }}
            </p>
        
            <p v-if="info.family[tabindex].js_cy_sex">
              <span>性别：</span>{{ info.family[tabindex].js_cy_sex | empty }}
            </p>
            <p v-if="info.family[tabindex].js_professional2">
              <span>职业：</span>{{ info.family[tabindex].js_professional2 | empty }}
            </p>
            <p v-if="info.family[tabindex].js_birth2">
              <span>出生年：</span>{{ info.family[tabindex].js_birth2 | empty }}
            </p>
            <p v-if="info.family[tabindex].jtcy_zz">
              <span>族裔：</span>{{ info.family[tabindex].jtcy_zz | empty }}
            </p>
            <p v-if="info.family[tabindex].jtcy_zmjlzt">
              <span>在美状态：</span>{{ info.family[tabindex].jtcy_zmjlzt | empty }}
            </p>
            <p v-if="info.family[tabindex].js_education2">
              <span>学历：</span>{{ info.family[tabindex].js_education2 | empty }}
            </p>
            <p v-if="info.family[tabindex].js_school2">
              <span>毕业院校：</span>{{ info.family[tabindex].js_school2 | empty }}
            </p>
            <p v-if="info.family[tabindex].jtcy_sfzxdzjz">
              <span>是否该地址居住：</span>{{ info.family[tabindex].jtcy_sfzxdzjz | empty }}
            </p>
          </div>
        </div>
        <div class="t2">
          <div class="title">房屋基本信息</div>
          <div class="box">
            <p v-if="info.js_type">
              <span>房屋类型：</span>{{ info.js_type | empty }}
            </p>
            <p v-if="info.js_bedroom">
              <span>卧室数量：</span>{{ info.js_bedroom | empty }}
            </p>
            
            <p v-if="info.js_freebedroom">
              <span>可用卧室数量：</span>{{ info.js_freebedroom | empty }}
            </p>
            <p v-if="info.js_bathroom">
              <span>洗手间数量：</span>{{ info.js_bathroom | empty }}
            </p>
            <p v-if="info.js_diwy">
              <span>是否提供独卫：</span>{{ info.js_diwy | empty }}
            </p>
            <p v-if="info.js_car">
              <span>汽车数量：</span>{{ info.js_car | empty }}
            </p>
            <!-- <p><span>无线网络：</span>Candidate</p> -->
            <p v-if="info.pet"><span>宠物：</span>{{ info.pet | empty }}</p>
          </div>
        </div>
        <!-- <div class="t3">
        <div class="title">家庭成员</div>
        <swiper class="swiper2" :options="swiperOptions2">
          <swiper-slide v-for="item in 5" :key="item">
            <img src="https://gimg2.baidu.com/image_search/src=http%3A%2F%2Fimg.pconline.com.cn%2Fimages%2Fupload%2Fupc%2Ftx%2Fitbbs%2F1308%2F16%2Fc8%2F24543062_1376637153786.jpg&refer=http%3A%2F%2Fimg.pconline.com.cn&app=2002&size=f9999,10000&q=a80&n=0&g=0n&fmt=jpeg?sec=1635392912&t=085fcad34cf92a245c152aaade876d6f" alt="">
            <p>
              <b>房东：</b>
              asdasdasdasd
            </p>
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
      </div> -->
        <div class="t4" v-if="info.jsjt_zrjy">
          <div class="title">家庭寄语</div>
          <div class="box">
            {{ info.jsjt_zrjy | empty }}
          </div>
        </div>
      </div>
    </div>
    <div class="anniu">
      <el-button @click="getPdf()">下载当前页面</el-button>
      <el-button @click="createPoster()">生成分享海报</el-button>
    </div>
    <!-- :style="{ backgroundImage: 'url(' + posterHtmlBg + ')' }" -->
    <div id="posterHtml" v-show="false">
      <img :src="coverImg" alt="" class="cover">
      <div class="bg">
        <img src="./img/haibao.png" alt="">
        <div class="title">{{ info.sheng ? info.sheng.name + '-' : '' }}{{ info.chengshi ? info.chengshi.name + '-' : '' }}{{ info.name }}
        </div>
      </div>
      <!-- <img :src="posterHtmlBg" alt=""> -->
      <div class="qrcode">
        <div id="qrcode" ref="qrcode"></div>
      </div>
    </div>
    <div class="haibao_box" v-show="haibao_box">
      <img class="haibao" :src="haibao" alt="" @click="1">
      <div class="ms">长按保存图片</div>
      <div class="x" @click="haibao_box = false"><i class="el-icon-close"></i></div>
    </div>
  </div>
</template>

<script>
import { Detail } from "@/api/detail";
import QRCode from "qrcodejs2";
import html2canvas from "html2canvas";
import { Loading } from 'element-ui';
export default {
  data() {
    return {
      haibao: '',
      haibao_box: false,
      posterHtmlBg: require("./img/haibao.png"),
      coverImg: require('@/assets/img/moren.png'),//默认图
      htmlTitle: "房源详情", //这个也是固定写法，pdf文件下载的名称
      schoolname: "",
      distance: "",
      info: {
        crmimg: [],
        family: [],
        houseimgs: [],
        chengshi: {},
        sheng: {},
        name: ""
      },
      tabindex: 0,
      swiperOptions1: {
        pagination: {
          el: ".swiper-pagination", //与slot="pagination"处 class 一致
          clickable: true,
          type: "fraction", //轮播按钮支持点击
        },
      },
      swiperOptions2: {
        pagination: {
          el: ".swiper-pagination", //与slot="pagination"处 class 一致
          clickable: true,
          type: "fraction", //轮播按钮支持点击
        },
      },
    };
  },
  created() {
    // console.log(window.location);
    const query = this.$route.query;
    if (query.schoolname) {
      this.schoolname = query.schoolname;
      this.distance = query.distance;
    }
    if (query.id) {
      Detail({
        id: query.id,
      }).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          if (res.data.family.length > 0) {
            //数组排序 父母、配偶、子女、亲戚、国际学生、租客、其他。
            res.data.family.forEach((element, i) => {
              //出生年只展示年份 截取前四位
              if (element.js_birth2) {
                element.js_birth2 = element.js_birth2.substring(0, 4);
              }
              //根据排序顺序 添加size标识
              switch (element.js_applicant) {
                case '父母':
                  element.size = 0
                  break;
                case '配偶':
                  element.size = 1
                  break;
                case '子女':
                  element.size = 2
                  break;
                case '亲戚':
                  element.size = 3
                  break;
                case '国际学生':
                  element.size = 4
                  break;
                case '租客':
                  element.size = 5
                  break;
                case '其他':
                  element.size = 6
                  break;
                default:
                  element.size = 7
                  break;
              }
            });
            //通过size标识 进行排序
            res.data.family.sort((a, b) => {
              return a.size - b.size
            })
          }
          if (res.data.houseimg.length > 0) {
            let index = res.data.houseimg.findIndex((item, key) => {
              return item.img_type == '封面图'
            })
            //没有上传封面图
            // console.log(index)
            if (index == -1) {
              this.coverImg = res.data.houseimg[0].imgs
              // console.log(this.coverImg)
            } else {
              this.coverImg = res.data.houseimg[index].imgs
            }
          }
          this.info = res.data;
        }
      });
    }
  },
  mounted() {
    this.rem();
    window.onresize = () => {
      this.rem();
    };
    this.$nextTick(() => {
      this.creatQrCode();
    })
  },
  methods: {
    // 生成海报
    createPoster() {
      const loading = this.$loading({
        lock: true,
        text: '生成中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      const vm = this;
      const domObj = document.getElementById("posterHtml");
      html2canvas(domObj, {
        useCORS: true,
        scale: 2, 
        dpi: 300,
        allowTaint: false,
        logging: false,
        letterRendering: true,
        onclone(doc) {
          let e = doc.querySelector("#posterHtml");
          e.style.display = "block";
        },
      }).then(function (canvas) {
        // 在微信里,可长按保存或转发
        vm.haibao = canvas.toDataURL("image/png");
        vm.haibao_box = true
        loading.close();
      });
    },
    // 生成二维码
    creatQrCode() {
      this.$refs.qrcode.innerHTML = ""; //清除二维码方法一
      let text = window.location.href; //二维码地址
      // console.log(text)
      var qrcode = new QRCode(this.$refs.qrcode, {

        text: text, //页面地址 ,如果页面需要参数传递请注意哈希模式#
        colorDark: "#000000",
        colorLight: "#ffffff",
        correctLevel: QRCode.CorrectLevel.H,
      });
      this.$refs.qrcode.children[1].style.width = "100%";
      this.$refs.qrcode.children[1].style.height = "100%";
    },
    tabclick(key) {
      this.tabindex = key;
    },
    //移动端rem适配计算
    rem() {
      // document.documentElement.style.fontSize=document.body.offsetWidth/7.5+"px"
      let designSize = 750; // 设计图尺寸
      let html = document.documentElement;
      // let wW = html.clientWidth; // 窗口宽度
      let wW = this.$refs.detail.offsetWidth; // 窗口宽度
      let rem = (wW * 100) / designSize;
      document.documentElement.style.fontSize = rem + "px";
    },
    //复制
    copy() {
      this.$copyText('Text to copy').then((res) => {
        console.log(res)
        this.$message({
          message: "复制成功",
          type: "success",
        });
      });
    },
    //拨打电话
    boda() {
      window.location.href = "tel:4009009770";
    },
  },
  //销毁
  destroyed() {
    window.onresize = null;
    document.documentElement.style.fontSize = "";
  },
};
</script>

<style scoped lang="scss">
/deep/ .el-message {
  font-size: 12px;
}

.detail {
  width: 100%;
  max-width: 640px;
  min-height: 100vh;
  margin: auto;
  overflow-x: hidden;
  font-size: 0.24rem;
  background: #f5f6f8;      
  >div {
    background: #f5f6f8;
  }

  .anniu {
    display: flex;
    justify-content: center;
    margin-bottom: 0.4rem;

    /deep/ .el-button {
      margin-bottom: 0.4rem;
      display: block;
      padding: 8px 14px;
      margin: 0 0.2rem;
    }
  }

  .swiper1 {
    height: 4.79rem;

    .swiper-slide {
      >.bg {
        height: 4.79rem;
      }
      >img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      >video {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .swiper-pagination-fraction {
      bottom: 1.19rem;
      left: 6.4rem;
      width: 0.68rem;
      height: 0.36rem;
      background: rgba(0, 0, 0, 0.3);
      border-radius: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: bold;
      color: #ffffff;
    }
  }

  .cnt {
    width: 100%;
    box-sizing: border-box;
    padding: 0 0.36rem;
    position: relative;
    top: -0.2rem;
    z-index: 10;

    .box {
      background: #ffffff;
      border-radius: 0.3rem;
      box-sizing: border-box;
      padding: 0.3rem 0.48rem;
      margin-bottom: 0.28rem;

      >p {
        font-size: 0.24rem;
        font-weight: 400;
        color: #454545;
        line-height: 0.5rem;

        >span {
          font-size: 0.24rem;
          font-weight: 400;
          color: #919191;
          display: inline-block;
          margin-right: 0.2rem;
        }
      }
    }

    .title {
      font-size: 0.32rem;
      font-weight: bold;
      color: #363636;
      padding-bottom: 0.28rem;
    }

    .t1 {
      >p {
        >span {
          width: 1.3rem;
        }
      }
    }

    .t2 {
      p {
        span {
          width: 1.68rem;
          text-align: right;
        }
      }
    }

    .t3 {
      .swiper2 {
        width: 100%;
        margin-bottom: 0.3rem;
        overflow: hidden;
        padding-bottom: 0.4rem;

        .swiper-slide {
          width: 6.78rem !important;
          background: #ffffff;
          box-shadow: 0px 0.02rem 0.24rem 0px rgba(146, 160, 170, 0.2);
          border-radius: 0.1rem;
          overflow: hidden;

          img {
            width: 100%;
            height: 2.79rem;
            object-fit: cover;
          }

          >p {
            display: flex;
            align-items: center;
            height: 0.7rem;
            box-sizing: border-box;
            padding: 0 0.35rem;
          }
        }

        .swiper-pagination-fraction {
          bottom: 0px;
        }
      }
    }

    .t4 {
      font-size: 0.24rem;
      font-weight: 400;
      color: #454545;
      line-height: 0.5rem;
    }

    .t5 {
      p {
        span {
          width: 2.2rem;
          text-align: right;
        }
      }

      .t5_tab {
        display: flex;
        flex-wrap: wrap;
        padding: 0.2rem 0;
        justify-content: space-between;

        >div {
          width: 1.71rem;
          height: 0.56rem;
          background: #d8dbe0;
          border-radius: 0.1rem;
          font-size: 0.28rem;
          font-weight: 400;
          color: #ffffff;
          text-align: center;
          line-height: 0.56rem;
          margin-bottom: 0.1rem;
        }

        .active {
          background: #079562;
        }

        &::after {
          content: "";
          width: 1.71rem;
        }
      }
    }
  }
}

#posterHtml {
  width: 100%;
  height: 13rem;
  overflow: hidden;
  position: relative;

  >.cover {
    width: 100%;
    min-height:9rem;
    // height: auto;
    object-fit: cover;
  }

  .bg {
    position: absolute;
    bottom: 0px;

    >img {
      width: 100%;
      height: auto;
      object-fit: cover;
      // object-fit: cover;
    }

    .title {
      position: absolute;
      bottom: 2.9rem;
      left: 0.4rem;
      color: white;
      font-size: 0.5rem;
      font-weight: bold;
      width: 6.6rem;
      word-break: break-all;
    }
  }

  >img {
    width: 100%;
    position: absolute;
    top: 0;
  }

  .qrcode {
    margin-top: 10.1rem;
    margin-left: 5.06rem;
    width: 2rem !important;
    height: 2rem !important;
    position: relative;
    z-index: 2;

    #qrcode {
      width: 2rem !important;
      height: 2rem !important;

      /deep/ img {
        width: 2rem !important;
        height: 2rem !important;
      }

      /deep/ canvas {
        width: 2rem !important;
        height: 2rem !important;
      }
    }
  }
}

.haibao_box {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 99;
  background: rgba(0, 0, 0, 0.8) !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .x {
    position: absolute;
    color: white;
    border: 1px solid white;
    right: 15px;
    top: 15px;
    border-radius: 50%;
    width: 0.5rem;
    height: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.haibao_box .ms {
  text-align: center;
  margin-top: 0.3rem;
  color: white;
  font-weight: bold;
  font-size: 0.3rem;
}

.haibao_box img {
  width: 80%;
  display: block;
  position: relative;
  z-index: 1;
  object-fit: cover;
}
</style>
